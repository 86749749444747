const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://ben-cohn.com/',
  title: 'BC.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Benjamin Cohn',
  role: 'Full-stack Developer',
  description:
    'I’m a creative-minded, solutions-driven engineer with an eye for visual aesthetics. My previous experience in tech startup operations has taught me to effectively craft technical documentation and act as an educator. I truly enjoy helping others to develop new projects and take pride in my ability to distill complex ideas into functional applications.',
  resume: 'https://example.com',
  social: {
    linkedin: 'https://www.linkedin.com/in/benjaminlcohn/',
    github: 'https://github.com/benlcohn',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Spaceman',
    description:
      'A fresh take the classic game of hangman: guess the word so spaceman can take off!',
    stack: ['JavaScript', 'HTML', 'CSS'],
    sourceCode: 'https://github.com/benlcohn/spaceman',
    livePreview: 'https://benlcohn.github.io/spaceman/',
    backgroundImage: "url('/SPACEMAN-BANNER-PORTFOLIO.png')"
  },
  {
    name: 'Foggyrito',
    description:
      'A restaurant review site, but only for burritos on the SF peninsula.',
    stack: ['JavaScript', 'Express', 'Mongo'],
    sourceCode: 'https://github.com/benlcohn/foggyrito',
    livePreview: 'https://foggyrito-4b7695cb1b55.herokuapp.com/',
    backgroundImage: "url('/FOGGYRITO-BANNER-PORTFOLIO.png')"
  },
  {
    name: 'MPC-MAPS',
    description:
      'A web-based Musical Production Computer: Upload samples, build drumkit layouts, and feel the rhythm',
    stack: ['React', 'Node', 'AWS'],
    sourceCode: 'https://github.com/benlcohn/mpc-maps',
    livePreview: 'https://mpc-maps-f12fcab484eb.herokuapp.com/',
    backgroundImage: "url('/MPC-MAPS-BANNER-PORTFOLIO.png')"

  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'HTML',
  'CSS',
  'React',
  'JavaScript',
  'Python',
  'Node',
  'Express',
  'MongoDB',
  'MaterialUI',
  'PostgreSQL',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'benlcohn@gmail.com',
}

export { header, about, projects, skills, contact }
